import React from "react";
import { Box, IconButton, Link } from "@mui/material";
import { GitHub, LinkedIn } from "@mui/icons-material";

const socialAccounts = [
  // { account: "LinkedIn", link: "https://www.linkedin.com/in/marcoantonioam", icon: LinkedIn },
  { account: "GitHub", link: "https://github.com/marcoariasm", icon: GitHub },
  // { account: "YouTube", link: "#" },
  // { account: "Twitter", link: "#" },
];

const SocialLinks = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        right: 0,
        bottom: 0,
        display: "flex",
        width: "auto",
        flexDirection: "column",
        fontSize: "40px",
        p: 1.5,
      }}
    >
      {socialAccounts.map(account => {
        return (
          <IconButton disableRipple>
            <Link href="https://marcoarias.com" underline="none" target="_blank" sx={{ bgColor: 'inherit', color: 'inherit' }}>
              <account.icon />
            </Link>
          </IconButton>
        )
      })}
    </Box>
  );
};

export default SocialLinks;
